import React from "react"
import Layout from "../components/layout.js"
import "./diorite.scss"
import DioriteHero from "../components/dioriteHero.js"
import DioriteServices from "../components/dioriteServices.js"
import DioriteProcedure from "../components/dioriteProcedure.js"
import BackgroundSection from "../components/backgroundSection.js"
import Head from "../components/head.js"

const DioriteContracting = () => {
  return (
    <>
      <section className="diorite-pg">
        <Head title={"Diorite Contracting"} />
        <BackgroundSection
          imgsrc={"dioritebg.jpg"}
          className="driorite__background"
        >
          <Layout page={"diorite"}>
            <div className="diorite__hero">
              <DioriteHero />
            </div>
            <DioriteServices />
            <DioriteProcedure />
          </Layout>
        </BackgroundSection>
      </section>
    </>
  )
}

export default DioriteContracting
