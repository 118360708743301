import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import "./dioriteProcedure.scss"
import { v4 as uuidv4 } from "uuid"

const DioriteProcedure = () => {
  const query = useStaticQuery(graphql`
    query {
      allContentfulDioriteProcedure(sort: { fields: createdAt, order: ASC }) {
        edges {
          node {
            step
            description {
              raw
            }
            photo {
              fluid {
                ...GatsbyContentfulFluid
              }
              file {
                url
              }
              description
            }
          }
        }
      }
    }
  `)

  return (
    <div className="steps">
      <h1 className="steps__title">Let's Get Started</h1>
      <div>
        {query.allContentfulDioriteProcedure.edges.map((step, i) => {
          return (
            <div className={`step step--${i + 1}`} key={uuidv4()}>
              <Img fluid={step.node.photo.fluid} className="step__picture" />
              <h3 className="step__title">{step.node.step}</h3>
              {/* <div className="step__description"> */}
              {documentToReactComponents(JSON.parse(step.node.description.raw))}
              {/* </div> */}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DioriteProcedure
