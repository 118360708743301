import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import "./dioriteServices.scss"
import { v4 as uuidv4 } from "uuid"

const DioriteServices = () => {
  const query = useStaticQuery(graphql`
    query {
      allContentfulDioriteServices(sort: { order: ASC, fields: createdAt }) {
        edges {
          node {
            title
            description {
              raw
            }
            picture {
              fluid {
                ...GatsbyContentfulFluid
              }
              file {
                url
              }
              description
            }
          }
        }
      }
    }
  `)

  return (
    <div className="services">
      <h1 className="services__title">Services</h1>
      {query.allContentfulDioriteServices.edges.map((service, i) => {
        // IF statement added to hide mold and remediation content. 
        if(i==0){
        // -------------------------------------
        return (
          <div
            className={`service service--${i % 2 === 0 ? "1" : "2"}`}
            key={uuidv4()}
          >
            <Img
              fluid={service.node.picture.fluid}
              className="service__picture"
            />
            <span className="service__details">
              <h2 className="service__title">{service.node.title}</h2>
              <div />
              {documentToReactComponents(
                JSON.parse(service.node.description.raw)
              )}
            </span>
          </div>
        )}
      })}
    </div>
  )
}

export default DioriteServices
