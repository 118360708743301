import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import "./dioriteHero.scss"
import GatsbyImageSharpFluid from "./gatsbyImageSharpFluid"

const DioriteHero = () => {
  const query = useStaticQuery(graphql`
    query {
      allContentfulDioriteRichardsMsg {
        edges {
          node {
            message {
              raw
            }
            companyLogo {
              fluid {
                ...GatsbyContentfulFluid
              }
              description
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="hero">
      <GatsbyImageSharpFluid
        imgsrc={"dioriteLogo1.png"}
        className="hero__logo"
      />
      <div className="hero__message">
        {documentToReactComponents(
          JSON.parse(
            query.allContentfulDioriteRichardsMsg.edges[0].node.message.raw
          )
        )}

        <p className="hero__signature">Richard Fababeir</p>
        <p className="hero__president">President</p>
      </div>
    </div>
  )
}

export default DioriteHero
